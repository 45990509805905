import request from '@/utils/request'
import { AxiosPromise } from 'axios'
import { LoginResult } from '@/api/types'


export function getCommentPage(data: any) {
  return request({
    url: `/meet/Comments/getListPage`,
    method: 'post',
    data
  })
}

/**
 * 消息列表
 * @param data {LoginData}
 * @returns
 */
export function getNoticeList(data: any) {
  return request({
    url: `/meet/notice/getNoticeList`,
    method: 'post',
    data
  })
}

/**
 * 消息内容
 * @param data {LoginData}
 * @returns
 */
export function getNoticeInfo(id: any) {
  return request({
    url: `/meet/notice/getNoticeInfo?id=${id}`,
    method: 'post',
  })
}

/**
 * 设置已读
 * @param data {LoginData}
 * @returns
 */
export function handleRead(id: any) {
  return request({
    url: `/meet/notice/handleRead?id=${id}`,
    method: 'post',
  })
}

/**
 * 上传图片
 * @param data {LoginData}
 * @returns
 */
export function uploadImg(data:any) {
  return request({
    url: `/open/File/pictureUpload`,
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundarynl6gT1BKdPWIejNq'
    }
  })
}

export function uploadFile(data:any) {
  return request({
    url: `/open/File/fileUpload`,
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundarynl6gT1BKdPWIejNq'
    }
  })
}


export function totalNotice() {
  return request({
    url: `/meet/notice/totalNotice`,
    method: 'post',
  })
}

/**
 * 审核统计
 * @param data {LoginData}
 * @returns
 */
export function totalExamine() {
  return request({
    url: `/meet/meeting/totalExamine`,
    method: 'post',
  })
}

export function getApplyList(params) {
  return request({
    url: `/meet/meeting/getApplyList?page=${params.page}&keywords=${params.keywords}`,
    method: 'post',
  })
}

